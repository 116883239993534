import Add from './add.js';
import Arrow from './arrow.js';
import ArrowLeft from './arrow-left.js';
import ArrowRight from './arrow-right.js';
import BackArrow from './backArrow.js';
import BackArrow1 from './backArrow1.js';
import Bandwidth from './bandwidth.js';
import BillingAddress from './billing-address.js';
import Calendar from './calendar.js';
import CalendarColor from './calendar-color.js';
import CalendarOutlined from './calendar-outlined.js';
import Cancel from './cancel.js';
import Catalogue from './catalogue.js';
import Check from './check.js';
import Chevron from './chevron.js';
import Chunk from './chunk.js';
import Chunks from './chunks.js';
import ChunkSlice from './chunk-slice.js';
import Clock from './clock.js';
import Cloud from './cloud.js';
import CloudWithCross from './cloud-with-cross.js';
import CloudWithKey from './cloud-with-key.js';
import CloudWithKeyHole from './cloud-with-key-hole.js';
import Code from './code.js';
import FormatCode from './code.js';
import Cog from './cog.js';
import Copy from './copy.js';
import Create from './create.js';
import CurrencyBill from './currency-bill.js';
import UserAccount from './userAccount.js';
import Dashboard from './dashboard.js';
import DeadTeddy from './dead-teddy.js';
import Delete from './delete.js';
import Document from './document.js';
import DragCircles from './drag-circles.js';
import Duplicate from './duplicate.js';
import Edit from './edit.js';
import Email from './email.js';
import Folder from './folder.js';
import FormatBold from './format_bold.js';
import FormatBullet from './format_list_bulleted.js';
import FormatItalic from './format_italic.js';
import FormatLink from './link.js';
import FormatList from './format_list_numbered.js';
import FormatQuote from './format_quote.js';
import FormatUnderline from './format_underline.js';
import Fulfilment from './fulfilment.js';
import FulfilmentOrder from './fulfilment-order.js';
import FulfilmentOrderOpen from './fulfilment-order-open.js';
import FulfilmentOrderWhite from './fulfilment-order-white.js';
import GraphExplorer from './graph-explorer.js';
import GraphQL from './graphQL.js';
import Grid from './grid.js';
import GridRelations from './grid-relations.js';
import Hooks from './hooks.js';
import IconError from './icon-error.js';
import IconInfo from './icon-info.js';
import IconWarning from './icon-warning.js';
import Info from './info.js';
import InfoIllustration from './info-illustration.js';
import ItemRelations from './item-relations.js';
import Items from './items.js';
import Key from './key.js';
import ListView from './list-view.js';
import LocationPin from './location-pin.js';
import Magnifier from './magnifier.js';
import Media from './media.js';
import Merge from './merge.js';
import MissingImage from './missing-image.js';
import Multilingual from './multilingual.js';
import Order from './order.js';
import Phone from './phone.js';
import Pin from './pin.js';
import PipelineRemove from './pipeline-remove.js';
import PipelineStageChange from './pipeline-stage-change.js';
import Price from './price.js';
import Product from './product.js';
import Publish from './publish.js';
import Quantity from './quantity.js';
import Renew from './renew.js';
import RenewDue from './renew-due.js';
import Reorder from './reorder.js';
import Required from './required.js';
import Reset from './reset.js';
import Save from './save.js';
import Scale from './scale.js';
import ShapeDocument from './shape-document.js';
import Shapes from './shapes.js';
import Sort from './sort.js';
import Split from './split.js';
import Star from './star.js';
import Subscription from './subscription.js';
import SuccessIllustration from './success-illustration.js';
import SwitchIcon from './switch.js';
import Target from './target.js';
import TaxGroup from './tax-group.js';
import Tenant from './tenant.js';
import Topics from './topic.js';
import Translation from './translation.js';
import Trashcan from './trashcan.js';
import TrashcanBucket from './trashcan-bucket.js';
import TrashcanColor from './trashcan-color.js';
import TrashcanLid from './trashcan-lid.js';
import Unpublish from './unpublish.js';
import Update from './update.js';
import Usage from './usage.js';
import User from './user.js';
import Visualize from './visualize.js';
import WarningIllustration from './warning-illustration.js';
import Customers from './customers.js';
export const Icon = {
  Add,
  Arrow,
  ArrowLeft,
  ArrowRight,
  BackArrow,
  BackArrow1,
  Bandwidth,
  BillingAddress,
  Calendar,
  CalendarColor,
  CalendarOutlined,
  Cancel,
  Catalogue,
  Customers,
  Check,
  Chevron,
  Chunk,
  Chunks,
  ChunkSlice,
  Clock,
  Cloud,
  CloudWithCross,
  CloudWithKey,
  CloudWithKeyHole,
  Code,
  Cog,
  Copy,
  Create,
  CurrencyBill,
  UserAccount,
  Dashboard,
  DeadTeddy,
  Delete,
  Document,
  DragCircles,
  Duplicate,
  Edit,
  Email,
  Folder,
  FormatBold,
  FormatBullet,
  FormatCode,
  FormatItalic,
  FormatLink,
  FormatList,
  FormatQuote,
  FormatUnderline,
  Fulfilment,
  FulfilmentOrder,
  FulfilmentOrderOpen,
  FulfilmentOrderWhite,
  GraphExplorer,
  GraphQL,
  Grid,
  GridRelations,
  Hooks,
  IconError,
  IconInfo,
  IconWarning,
  Info,
  InfoIllustration,
  ItemRelations,
  Items,
  Key,
  ListView,
  LocationPin,
  Magnifier,
  Media,
  Merge,
  MissingImage,
  Multilingual,
  Order,
  Phone,
  PipelineRemove,
  Pin,
  PipelineStageChange,
  Price,
  Product,
  Publish,
  Quantity,
  Renew,
  RenewDue,
  Reorder,
  Required,
  Reset,
  Save,
  Scale,
  ShapeDocument,
  Shapes,
  Sort,
  Split,
  Star,
  Subscription,
  SuccessIllustration,
  SwitchIcon,
  Target,
  TaxGroup,
  Tenant,
  Topics,
  Translation,
  Trashcan,
  TrashcanBucket,
  TrashcanColor,
  TrashcanLid,
  Unpublish,
  Update,
  Usage,
  User,
  Visualize,
  WarningIllustration
};